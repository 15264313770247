#footer {
    background: $footer-bg;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $footer-color-text;
    font-size: 20px;
    padding-top: 10vw;
    padding-bottom: $container-gap-vertical;
    padding-left: calc(#{$container-gap-horizontal} + 13vw);
    padding-right: $container-gap-horizontal;
    position: relative;
    z-index: 600;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    &::after {
        background-image: url("../../../../../../fileadmin/hh_theme_prechtl/images/logos/logo_icon.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: " ";
        display: inline-block;
        height: 0;
        left: 15%;
        padding-bottom: 13%;
        position: absolute;
        top: 5vw;
        width: 9%;
    }

    a {
        color: $footer-color-a;

        &:hover,
        .active {
            color: $footer-color-a-hover;
        }

        &.btn-blue:hover {
            color: #fff;
        }
    }

    p {
        font-size: inherit;
    }

    .frame {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    .col > * {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .col-xs-3 {
        max-width: 200px;
    }

    .col-xs-3:last-child {
        max-width: 100%;

        ul {
            li {
                &::after {
                    background-color: inherit;
                    content: "|";
                    display: inline-block;
                    height: 100%;
                    position: relative;
                    width: 1px;
                }

                &:last-child::after {
                    display: none;
                }
            }
        }
    }

    .ce-bodytext {
        width: 100%;
        max-width: 100%;
    }

    @media screen and (min-width: $layout-max-size + 10px) {
        padding-left: calc(#{$container-gap-horizontal-over} + 249px);
        padding-right: $container-gap-horizontal-over;
        padding-top: 200px;

        &::after {
            top: 100px;
        }
    }

    @media screen and (max-width: 1400px) {
        .ce-bodytext ul.inline {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }

    @media screen and (max-width: 600px) {
        padding-left: 40px;
        padding-right: 40px;

        &::after {
            display: none;
        }

        .ce-bodytext ul.inline {
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
}
