$breakpoints: (
    small : 300px,
    medium: 700px,
    large : 1024px
);
$mobile: 1000px;

$color-blue: #001e2f;
$color-grey: #9d9d9c;
$color-grey-light: #dfe6ea;


$color-brand: #ffeb36;
$color-brand2: #384a50;

$text-color: #000;

/* ------------------------------------- */

$a-color: $color-brand2;
$a-hover-color: $color-brand;
$a-hover-transition-speed: 400ms;

/* ------------------------------------- */

$btn-transition-speed: 500ms;
$btn-blue-color-bg: $color-brand;
$btn-blue-color-bg-hover: $color-blue;
$btn-blue-color-text: #fff;
$btn-blue-color-text-hover: #fff;

/* Typography */
$basefont: 'Source Sans Pro', sans-serif;
$font-size-base:        14px;
$font-size-base-medium: 14px;
$font-size-base-large:  14px;

$line-height-base:      1.5em;
$font-weight-base:      300;
$font-weight-bold:      500;

/* ------------------------------------- */

$heading-font:          $basefont;
$heading-weight:        500;
$heading-case:          none;

$h1-font-sizes: (
    null  : 4.4em,
    small : 2em,
    medium: 3.4em,
    large : 4.4em
);

$h2-font-sizes: (
    null  : 2.2em,
    small : 1.8em,
    medium: 2em,
    large : 2.4em
);

$h3-font-sizes: (
    null  : 2em,
    small : 1.6em,
    medium: 1.8em,
    large : 2.4em
);

$h4-font-sizes: (
    null  : 1.6em,
    small : 1.6em,
    medium: 1.6em,
    large : 1.6em
);

$h5-font-sizes: (
    null  : 1.63em,
    small : 2em,
    medium: 2.3em,
    large : 2.4em
);

$h2-subheader-size: (
    null  : 2.07em,
    small : 2em,
    medium: 2.3em,
    large : 2.4em
);

$h3-subheader-size: (
    null  : 1.85em,
    small : 2em,
    medium: 2.3em,
    large : 2.4em
);

$h4-subheader-size: (
    null  : 1.7em,
    small : 2em,
    medium: 2.3em,
    large : 2.4em
);

$h5-subheader-size: (
    null  : 1.5em,
    small : 2em,
    medium: 2.3em,
    large : 2.4em
);

/* ------------------------------------- */

$font-size-default: $font-size-base;

$p-font-sizes: (
    null  : 1.3em,
    small : 1.3em,
    medium: 1.3em,
    large : 1.3em
);

$font-sizes-xlarge: (
    null  : 2.4em,
    small : 1.7em,
    medium: 2.3em,
    large : 2.4em
);

$font-sizes-large: (
    null  : 1.6em,
    small : 1.3em,
    medium: 1.6em,
    large : 1.6em
);

$font-sizes-medium: (
    null  : 2em,
    small : 1.15em,
    medium: 1.8em,
    large : 2em
);

$font-sizes-small: (
    null  : 1.8em,
    small : 1.1em,
    medium: 1.6em,
    large : 1.8em
);

$font-sizes-xsmall: (
    null  : 1.6em,
    small : 1em,
    medium: 1.3em,
    large : 1.6em
);

$letter-spacing-1: 1px;
$letter-spacing-2: 2px;

/* ------------------------------------- */

/* Container */
$layout-max-size: 1920px;

// $container-gap-horizontal: 45px;
// $container-gap-vertical: 50px;

$container-gap-horizontal: 21vw;
$container-gap-vertical: 100px;

$container-gap-horizontal-over: 403px;

/* Header */
/* for header items like menu a, language a */
$header-items-padding-horizontal: 1.8vw;
$header-items-padding-vertical: 1.8vw;

$header-items-padding-horizontal-over: 30px;
$header-items-padding-vertical-over: 30px;

/* Menus */
/* Menu Main */
$menu-main-font-size: 22px;
$menu-main-color-bg: transparent;
$menu-main-color-a: #fff;
$menu-main-color-a-hover: #fff;
$menu-main-color-a-bg: transparent;
$menu-main-color-a-bg-hover: transparent;

$menu-main-color-sub-bg: rgba(56, 74, 80, 0.6);
$menu-main-color-sub-a: #fff;
$menu-main-color-sub-a-hover: #fff;

$menu-mobile-btn-color: $color-brand;

/* Menu Language */
$menu-language-font-size: 17px;

/* Footer */
$footer-bg: $color-brand2;
$footer-color-text: $color-grey;
$footer-color-a: $color-grey;
$footer-color-a-hover: $color-brand;

/* Searchbox */
$searchbox-font-size: $menu-main-font-size;

/* QuickLinks */
$quickLink-BG-Color: $color-brand;
